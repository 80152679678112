<template>
  <div class="flex h-full" v-if="!connect">
    <div class="w-1/3 min-w-max p-16 flex flex-col justify-center">

      <div class="flex justify-center">
        <img src="./assets/logo.png" class="w-40 mb-16">
      </div>

      <!-- <h1 class="text-center mb-8">DevCloud Remote Desktop</h1> -->

      <div class="flex items-center mb-6">
        <div class="floating-label-group w-1/3 mr-3">
          <select v-model="scheme" id="scheme" placeholder=" ">
            <option value="rdp">RDP</option>
            <option value="ssh">SSH</option>
          </select>
          <label for="scheme">Protocol</label>
        </div>

        <div class="floating-label-group flex-1 ml-3">
          <input type="text" v-model="hostname" placeholder=" " id="hostname" :class="{ 'invalid': hasError }" class="h-full">
          <label for="hostname">Address</label>
          <span class="invalid-feedback">Invalid IP</span>
        </div>
      </div>

      <div class="floating-label-group mb-6">
        <label for="user" placeholder=" ">Username</label>
        <input type="text" v-model="user" id="user">
      </div>

      <div class="floating-label-group mb-6">
        <label for="pass" placeholder=" ">Password</label>
        <input type="password" v-model="pass" id="pass">
      </div>

      <div class="flex justify-between items-center mb-6">
        <div class="flex items-center">
          <label class="control control--checkbox">
            <input type="checkbox" v-model="checked">
            <div class="control__indicator rounded"></div>
          </label>

          <div class="ml-2 mt-1 cursor-default select-none" @click="checked = !checked">Use WebSocket</div>
        </div>

        <div class="text-right">
          <button class="button" @click="doConnect()">Connect</button>
        </div>
      </div>
    </div>

    <div id="particles-js" class="particle-bg"></div>

  </div>

  <guac-client v-else :query="query" :force-http="forceHttp" />
</template>

<script>
import GuacClient from '@/components/GuacClient.vue';
import './assets/js/particles';
import './assets/js/particle-config';

export default {
  name: 'app',
  components: {
    GuacClient,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      checked: true,
      connect: false,
      scheme: 'rdp',
      hostname: '',
      port: '',
      user: 'devcloud',
      pass: '',
      forceHttp: false,

      hasError: false,
    };
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    queryObj() {
      return {
        scheme: this.scheme,
        hostname: this.hostname,
        port: this.port,
        username: this.user,
        password: this.pass,
      };
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    query() {
      const queryString = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [k, v] of Object.entries(this.queryObj)) {
        if (v) {
          queryString.push(`${k}=${encodeURIComponent(v)}`);
        }
      }
      return queryString.join('&');
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    scrubbedQuery() {
      return this.query.replace(/password=[^& ]+/, 'password=****');
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    doConnect() {
      const ipRegex = new RegExp('^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\\.(?!$)|$)){4}$');
      if (!ipRegex.test(this.hostname)) {
        this.hasError = true;
        return;
      }
      if (window.localStorage) {
        window.localStorage.setItem('query', JSON.stringify(this.queryObj));
      }
      this.connect = true;
    },
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    hostname() {
      const ipRegex = new RegExp('^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\\.(?!$)|$)){4}$');
      if (this.hasError && ipRegex.test(this.hostname)) {
        this.hasError = false;
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    checked() {
      this.forceHttp = !this.checked;
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted() {
    if (window.localStorage && window.localStorage.getItem('query')) {
      let query;
      try {
        query = JSON.parse(window.localStorage.getItem('query'));
      } catch (e) {
        window.localStorage.setItem('query', '{}');
        return;
      }
      this.scheme = query.scheme;
      this.hostname = query.hostname;
      this.port = query.port;
      this.user = query.username;
      this.pass = query.password;
    }
  },
};
</script>
